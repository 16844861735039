export default {

  setAllDiscounts(state, data) {
    state.allDiscounts = data;
  },

  setDiscountCounts(state, data) {
    state.discountCounter = data;
  },

  setPoint(state, data) {
    state.point = data;
  },

  setForget(state, data) {
    state.forget = data;
  },

  setOffPlan(state, data) {
    state.offPlan = data;
  },

  setSale(state, data) {
    state.sale = data;
  },

  setEvent(state, data) {
    state.event = data;
  },

  setCashback(state, data) {
    console.log(data)
    state.cashback = data;
  },
  setCredit(state, data) {
    state.credit = data;
  },

  setLoyalty(state, data) {
    state.loyalty = data;
  },

  setPublic(state, data) {
    state.public = data;
  },

};

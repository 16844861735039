export default {
  setCheckCode(state, data) {
    state.checkCode = data;
  },
  setTimer(state, data) {
    state.timer = data;
  },

  authUser(state, data) {
    state.token = data.token;
  },

  setLoginCheck(state, data) {
    state.loginCheck = data;
  },

  setForgotCheck(state, data) {
    state.forgotCheck = data;
  },
  setForgotBtn(state, data) {
    state.forgotBtn = data;
  },

  setCurrentUser(state, data) {
    state.userProfile = data
  },

  clearAuth(state) {
    state.token = null;
  },

  // Alert Handles ---------------------------------------

  setPostCheck(state, data) {
    state.postCheck = data;
  },

  setInfo(state, data) {
    state.checkAlert = 4;
    state.infoMessage = data;
  },

  setWarning(state, data) {
    state.checkAlert = 3;
    state.warningMessage = data;
  },

  setError(state, data) {
    state.checkAlert = 2;
    state.dangerMessage = data;
  },

  setSuccess(state, data) {
    state.checkAlert = 1;
    state.successMessage = data;
  },

  expireAlert(state) {
    state.checkAlert = 0;
    state.successMessage = null;
    state.dangerMessage = null;
    state.warningMessage = null;
    state.infoMessage = null;
  },

  setLoading(state, data) {
    state.loadingCheck = data.status;
    state.loadingMessage = data.message;
  },

  setLoadingBtn(state, data) {
    state.loadingBtn = data
  },


};

import axios from "axios";
// import alert from "@/lang/AlertMessages.js";
// import label from "@/lang/StaticLabels.js";

export default {
  // Levels Actions ------------------------------------------------------------------
  getLevels({ dispatch, commit }) {
    axios
      .get("dropdown/level")
      .then((res) => {
        commit("setLevels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Lebels Actions ------------------------------------------------------------------
  getLabels({ dispatch, commit }) {
    axios
      .get("dropdown/label")
      .then((res) => {
        commit("setLabels", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Province Actions ------------------------------------------------------------------
  getProvinces({ dispatch, commit }) {
    console.log("Guuu")
    axios({
      method: "get",
      url: "https://iran-locations-api.ir/api/v1/fa/states"
    })
      .then((res) => {
        console.log(res.data)
        commit("setProvinces", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
  },

  getCities({ dispatch, commit }, data) {
    console.log("سلام بر تو" + data)
    axios({
      method: "get",
      url: "https://iran-locations-api.ir/api/v1/fa/cities?state=" + data
    })
      .then((res) => {
        console.log(res.data)
        commit("setCities", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Banks Actions ------------------------------------------------------------------
  getBanks({ dispatch, commit }) {
    axios
      .get("dropdown/bank")
      .then((res) => {
        commit("setBanks", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // VendorCategory Actions ------------------------------------------------------------------
  getVendorCategory({ dispatch, commit }) {
    axios
      .get("Business/GetAllCategories")
      .then((res) => {
        console.log(res.data.categories)
        commit("setVendorCategory", res.data.categories);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getVendor({ dispatch, commit }, data) {
    axios
      .get("Business/GetAllVisibleBusiness?CategoryId" + data)
      .then((res) => {
        console.log(res.data.visibleBusinesses)
        commit("setVendors", res.data.visibleBusinesses);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Customers Options ----------------------------------------------------------
  getCustomers({ dispatch, commit }) {
    axios
      .get("dropdown/customer")
      .then((res) => {
        commit("setCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

};

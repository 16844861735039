let language = localStorage.getItem("selectedLanguage");
let label;
if (language && language === "En") {
  label = {
    page: {
      home: "Home",
      dashboard: "Dashboard",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      baseInformation: "Base Informations",
      users: "Users",
      products: "Products",
      addProduct: "New Product",
      editProduct: "Edit Product",
      orders: "Orders",
      addOrder: "New Order",
      editOrder: "Edit Order",
      support: "Support",
      tickets: "Tickets",
      addTicket: "Open Ticket",
      messages: "Messages",
      profile: "Profile",
      login: "Sign In",
      register: "Sign Up",
      notFound: "Oops! Are You Lost ?!",
      notAccess: "Sorry! You Don't Have Access To This Page",
      noAccess: "No Access",
      serverError: "Internal Server Error! Please Call The Technical Support",
      underConstruction:
        "This Page is Under Construction and will be Available Soon",
    },
    menu: {
      home: "Home",
      dashboard: "Dashboard",
      manageShop: "Store Manager",
      levelCustomer: "Level management",
      manageCustomer: "Customer management",
      incentiveDiscountPlans: "Discount/incentive plans",
      manageAward: "Management points and awards",
      vote: "Vote",
      smartData: "Smart data",
      shareCustomerClub: "Share customer club",
      populationCenters: "Population centers",
    },
    button: {
      login: "Sign In",
      register: "Sign Up",
      logout: "Logout",
      recoveryEmail: "Send Recovery Email",
      submit: "Submit",
      submitInfo: "Submit Informations",
      save: "Save",
      saveChanges: "Save Changes",
      add: "Add New",
      addUser: "New User",
      edit: "Update",
      delete: "Delete",
      confirmDelete: "Yes, Delete",
      editProfile: "Update Profile",
      editPassword: "Update Password",
      send: "Send",
      back: "Back",
      backToLogin: "Back to Login",
      backToHome: "Back to Home",
      home: "Home",
      cancel: "Cancel",
      addBase: "Add Base Info",
      reload: "Reload",
      addProduct: "Save Product",
      editProduct: "Update Profile",
    },
    table: {
      row: "#",
      title: "Title",
      formDate: "From Date",
      toDate: "To Date",
      fromTime: "From Time",
      toTime: "To Time",
      discount: "Credit/discount",
      action: "Action",
    },
    form: {
      firstName: "First Name",
      lastName: "Last Name",
      fullName: "Full Name",
      userName: "Username",
      password: "Password",
      repeatPassword: "Repeat Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      email: "Email Address",
      mobile: "Mobile Number",
      tel: "Phone Number",
      phone: "Telephone",
      address: "Address",
      zipCode: "Zip Code",
      otpCode: "OTP Code",
      image: "Image",
      file: "File",
      fileSize: "Max File Size",
      fileType: "File Types",
      fileRatio: "Better To Use Square Ratio Picture",
      department: "Department",
      birthDate: "Birth Date",
      description: "Description",
      content: "Content",
      message: "Message",
      subject: "Subject",
      title: "Title",
      qty: "Quantity",
      digit: "Digit",
      category: "Category",
      city: "City",
      role: "User Role",
      productName: "Product Name",
      annualDemand: "Annual Demand",
      sbu: "SBU",
      resell: "Resell",
      weight: "Special Weight",
      sendDate: "Sent Date",
      productDate: "Production Date",
      paymentMethod: "Payment Method",
      cashOption: "Cash",
      chequeOption: "Cheque",
      attachment: "Attachment",
      dropzoneTxt: "Click or Drop File here",
    },
    caption: {
      yes: "Yes",
      no: "No",
      ok: "Ok",
      dashboard: "Dashboard",
      profile: "Update Profile",
      password: "Change Password",
      avatar: "Profile Avatar",
      baseInformation: "Basic Informations",
      addBaseInformation: "Add Basic Info",
      editBaseInformation: "Update Basic Info",
      users: "Users Management",
      addUser: "New User",
      editUser: "Update User",
      products: "Products Management",
      orders: "Manage Orders",
      messages: "Messages and Chats",
      tickets: "Tickets",
      loading: "Loading ...",
      singIn: "Sign in",
      singUp: "Sign Up",
      haveAccount: "Do you have Account ?",
      haveNotAccount: "Don't have Account ?",
      forgotPassword: "Forgot Password ?",
      emptyItem: "Item Not Found",
      emptyMessage: "There is no Message",
      emptyTicket: "There is no Tickets",
      emptyNotify: "There is no new Notifications",
      noMessage: "There is no new Message",
      noNotify: "There is no new Notification",
      underConstruction:
        "This Section is Under Construction and will be available soon",
      rejectedTitle: "No Access",
      rejectedText: "Your Account has been blocked or Reported",
      search: "Search",
      searchTable: "Search Items",
      searchAllColumns: "Search in all columns",
      delConfirm: "Are you sure you want to delete this item ?",
      resell: "is this Item for Resell ?",
    },
  };
} else {
  label = {
    page: {
      home: "صفحه اصلی",
      dashboard: "داشبورد مدیریتی ",
      profile: "ویرایش پروفایل",
      incentiveDiscountPlans: "طرح‌های تخفیفی/تشویقی",
      smartData: "دیتای هوشمند",
      shareCustomerClub: "اشتراک‌گذاری باشگاه مشتریان",
      login: "ورود به برنامه",
      FurtheInformation: "اطلاعات تکمیلی",
      changeMobile: "تغییر شماره همراه",
      register: "عضویت",
      notFound: "راهتان را گم کرده‌اید ؟!",
      notAccess: "متأسفانه دسترسی شما برای مشاهده این قسمت محدود شده است",
      noAccess: "عدم دسترسی",
      serverError: "خطای ارتباط با سرور؛ لطفا به پشتیبانی فنی اطلاع دهید",
      underConstruction: "صفحه مدنظر در حال توسعه و برورسانی می‌باشد",
      listRequests: "لیست درخواست‌ها",
      sendMassage: "ارسال پیام",
      sendRequest: "ارسال درخواست",
      detailRequest: "جزئیات درخواست",
      businessList: "لیست کسب و کارها",
      point: "طرح امتیازی",
      forget: "طرح فراموشی",
      discount: "طرح تخفیفی",
      sale: "فروش ویژه",
      event: "مدیریت مناسبت‌ها",
      cashback: "طرح کش بک",
      credit: "طرح اعتباری",
      loyality: "طرح وفاداری",
      general: "طرح عمومی",
      newLevel: "سطح‌بندی جدید",
      addNewGift: "افزودن جایزه جدید",
      addDesign: "طرح جدید",
      selectBranch: "انتخاب شعبه و بخش ",
      subTitleHeaderPoint:
        " در ازای میزان خرید مشخص، به مشتریان امتیاز بدهید.",
      subTitleHeaderForget:
        " در ازای بازه زمانی خرید نکردن، به کاربران خودتون تخفیف بدید.",
      subTitleHeaderDiscount:
        " به ازای میزان خرید، به کاربران خودتون تخفیف یا اعتبار هدیه بدید.",
      subTitleHeaderSale:
        "بازه زمانی برای فروش ویژه رو مشخص کنید و به کاربران هدیه بدید.",
      subTitleHeaderEvent:
        "در مناسبت‌های خاص به کاربران خودتون هدیه شخصی یا جمعی بدید.",
      subTitleHeaderCredit: "به کاربران هر بخشی که می خواهید اعتبار هدیه بدید",
      subTitleHeaderCashback: "به کاربران هر بخشی که میخواهید کش بک هدیه بدید",
      subTitleHeaderLoyality: "با تغییر سطح و پیشرفت کاربران، هدیه بدهید",
      subTitleHeaderGeneral:
        "برای همه مشتریان یک بخش خاص از شعبه، تخفیف در نظر بگیرید",
    },
    menu: {
      home: "صفحه اصلی",
      dashboard: "داشبورد",
      manageShop: "مدیریت فروشگاه",
      levelCustomer: "سطح بندی مشتریان",
      customerCategories: "دسته‌بندی مشتریان",
      manageCustomer: "مدیریت مشتریان",
      logout: "خروج",
      incentiveDiscountPlans: "طرح‌های تخفیفی/تشویقی",
      manageGift: " مدیریت امتیاز و جایزه ",
      manageGiftScore: " مدیریت امتیاز و قفسه جایزه ",
      vote: "نظرسنجی",
      addVoteM: "افزودن نظرسنجی",
      detailVote: "جزئیات نظرسنجی",
      smartData: "دیتای هوشمند",
      shareCustomerClub: "اشتراک گذاری باشگاه مشتریان",
      populationCenters: "مراکز جمعیت",
      businessManagement: "مدیریت کسب و کارها",
      detailCustomer: "جزئیات مشتری",
      giftUserList: "لیست مشتریان",
      userList: "لیست کاربران",
      editProfile: "ویرایش پروفایل",
      addCustomer: "افزودن مشتری ",
      youerRequestList: "لیست درخواست‌های شما",
      prizeShelf: "قفسه جوایز",
    },
    button: {
      login: "ورود به سامانه",
      register: "ثبت نام در سامانه",
      logout: "خروج از سامانه",
      recoveryEmail: "ارسال ایمیل بازیابی",
      sendVerificationCode: "ارسال کد تایید",
      submit: "ثبت",
      ok: "تایید",
      addDesign: "افزودن طرح",
      save: "ذخیره",
      saveChanges: "ثبت تغییرات",
      add: "ثبت جدید",
      addCustomer: "افزودن مشتری جدید",
      addNewVote: "افزودن نظرسنجی جدید",
      edit: "ویرایش",
      delete: "حذف",
      confirmDelete: "بله، حذف شود",
      confirmSend: "بله، ارسال شود",
      editDetailUser: "ویرایش کاربر",
      deleteDetailUser: "حذف کاربر",
      editPassword: "ویرایش رمز عبور",
      send: "ارسال",
      back: "بازگشت",
      backToLogin: "بازگشت به صفحه ورود",
      backToHome: "بازگشت به صفحه اصلی",
      home: "صفحه اصلی",
      cancel: "انصراف",
      addBase: "ثبت اطلاعات پایه",
      reload: "بارگذاری مجدد",
      addProduct: "ثبت محصول",
      editProduct: "ویرایش محصول",
      sendRequest: "ارسال درخواست",
      viewList: "مشاهده لیست کسب و کارها",
      confirmRequest: "تایید درخواست",
      rejectRequest: "رد درخواست",
      noticed: "متوجه شدم",
      addLevel: "افزودن سطح",
      addQuestion: "افزودن سوال",
      addVote: "افزودن نظرسنجی",
      addOption: "افزودن گزینه",
      addNewGift: "افزودن جایزه",
      addSection: "افزودن بخش",
      addNewPart: "افزودن بخش جدید",
      addSingle: "افزودن تکی",
      addGroup: "افزودن گروهی",
      customerList: "لیست مشتریان",
      details: "مشاهده جزئیات",
      addBranch: "افزودن شعبه",
      saveChange: "ذخیره تغییرات",
      addExel: "افزودن گروهی با اکسل",
      addBankCards: "افزودن کارت",
      closeWindow: "بستن",
      sendNewRequest: "ارسال درخواست جدید",
    },
    table: {
      row: "ردیف",
      title: "عنوان",
      formDate: "از تاریخ",
      toDate: "تا تاریخ",
      fromTime: "از ساعت",
      toTime: "تا ساعت",
      time: "ساعت",
      price: "مبلغ",
      type: "نوع",
      discount: " اعتبار /تخفیف",
      actions: "عملیات",
      requestType: "نوع درخواست",
      requestTo: "درخواست به",
      requestFor: "درخواست برای",
      requestFrom: "درخواست از",
      status: "وضعیت",
      waitingConfirmation: "در انتظار تایید",
      confirmation: "تایید شده",
      rejection: "رد شده",
      cost: "هزینه (تومان)",
      name: "نام",
      birthDay: "تاریخ تولد",
      job: "شغل",
      gender: "جنسیت",
      nationalCode: "کدملی",
      marriedOrSingle: "وضعیت تاهل",
      anniversary: "سالگرد ازدواج",
      state: "استان",
      city: "شهر",
      date: "تاریخ",
      purchaseAmount: "میزان خرید",
      store: "فروشگاه",
      score: "امتیاز",
      scoreReceived: "امتیاز دریافتی",
      voteTitle: "عنوان نظرسنجی",
      numQuestion: "تعداد سوالات",
      numParticipants: "تعداد شرکت کنندگان",
      dateResive: "تاریخ دریافت",
      Resived: "دریافت کرده",
      notResived: "دریافت نکرده",
      transactionNumber: "شماره تراکنش",
      transactions: "تراکنش‌ها",
      transactionDesc: "شرح تراکنش",
      successful: "موفق",
      unSuccessful: "ناموفق",
      branch: "شعبه",
      part: "بخش",
      vendor: "فروشنده",
      messagableType: "نوع درخواست",
      targetText: "گروه هدف",
      platformText: "بستر پیام",
      messageText: "متن پیام",
      statusText: "وضعیت درخواست",
      sent: "ارسال شده",
      discountType: "نوع تخفیف",
      discountValue: "مقدار تخفیف",
      level: "سطح",
      mobile: "تلفن همراه",
      phone: "تلفن ثابت",
      zipCode: "کد پستی",
      address: "آدرس",
      email: "پست الکترونیک",
      createdDate: "تاریخ عضویت",
    },
    form: {
      firstName: "نام",
      lastName: "نام خانوادگی",
      fullName: "نام و نام خانوادگی",
      userName: "نام کاربری",
      password: "رمز عبور",
      repeatPassword: "تکرار رمز عبور",
      currentPassword: "رمز عبور فعلی",
      newPassword: "رمز عبور جدید",
      email: "پست الکترونیک",
      mobile: "شماره همراه",
      tel: "شماره تماس",
      phone: "تلفن ثابت",
      address: "آدرس",
      zipCode: "کد پستی",
      otpCode: "کد احراز هویت (کد تأیید)",
      image: "تصویر",
      file: "فایل",
      fileSize: "حداکثر حجم مجاز",
      fileType: "پسوندهای مجاز",
      fileRatio: "تصویر در ابعاد مربعی انتخاب کنید",
      department: "دپارتمان",
      birthDay: "تاریخ تولد",
      anniversary: "سالگرد ازدواج",
      description: "توضیحات",
      content: "متن و محتوا",
      message: "متن پیام",
      subject: "موضوع",
      title: "عنوان",
      qty: "تعداد",
      qtyPurchases: " تعداد خرید",
      purchases: "خرید",
      giftValue: "مقدار هدیه",
      creditValue: "مقدار اعتبار",
      digit: "عدد",
      category: "دسته بندی",
      city: "شهر",
      province: "استان",
      role: "نقش کاربر",
      attachment: "فایل پیوست",
      dropzoneTxt: "کلیک و یا فایل را در اینجا رها کنید",
      scoreType: "نوع امتیازدهی",
      creditType: "نوع اعتباردهی",
      scheduleType: "نوع زمانبندی",
      discountType: "نوع تخفیف",
      eventType: "نوع مناسبت‌ها",
      designTitle: "عنوان طرح",
      fromPrice: "از قیمت",
      toPrice: "تا قیمت",
      fromScore: "از امتیاز ",
      toScore: "تا امتیاز",
      forEach: "به ازای هر",
      typePrice: "تومان",
      creditTo: "اعتبار تا",
      score: "امتیاز",
      person: "نفر",
      scoreReceived: " امتیاز دریافتی",
      level: "سطح موردنظر",
      organizationType: "نوع سازمان",
      smartDetailTitle: "پیام برای چه شماره‌هایی ارسال شود:",
      allPeople: "تمام افراد گروه هدف",
      specialPeople: "کسانی که خط آنها محدودیت پیامکی ندارد",
      messagePlatform: "پیام در چه بستری منتشر شود:",
      sms: "پیام کوتاه",
      notification: "نوتیفیکیشن",
      application: "اپلیکیشن",
      selectCategory: "انتخاب دسته‌بندی",
      selectBusiness: "انتخاب کسب و کار",
      requestType: "نوع درخواست",
      fromStore: "از فروشگاه",
      sendTo: "ارسال برای",
      sendCost: "هزینه ارسال",
      sendMessageCost: "هزینه ارسال پیام",
      giftShelf: "قفسه جایزه",
      validityDate: "تاریخ اعتبار",
      selectLevel: "انتخاب سطح",
      voteTitle: "عنوان نظرسنجی",
      sendLevel: "ارسال برای سطح",
      questionOne: "سوال اول",
      optionType: "نوع گزینه",
      giftRecipients: "افراد مشمول جایزه",
      numberRecipients: "تعداد دریافت شده",
      giftTitle: "عنوان جایزه",
      sectionTitle: "عنوان بخش",
      amountPointsReceive: "میزان امتیاز برای دریافت جایزه",
      awardType: "نوع جایزه",
      giftType: "نوع هدیه",
      validity: "اعتبار",
      validityValue: "میزان اعتبار",
      discount: "تخفبف",
      selectShelfGift: "انتخاب از قفسه جایزه",
      dateResiveGift: "تاریخ دریافت جایزه",
      giftStatus: "وضعیت دریافت جایزه",
      specialTimeDateDis: "برای این طرح، تاریخ و ساعت خاصی مدنظر دارم.",
      specialTimeDis: "برای این تخفیف ساعت خاصی مدنظر دارم",
      definedMinPurchase: "میخواهم حداقل میزان خرید را مشخص کنم",
      businessCategory: "دسته‌بندی کسب و کار",
      businessName: "نام کسب و کار",
      selectBranch: "انتخاب شعبه",
      selectPart: "انتخاب بخش",
      usedFor: "مورد استفاده برای",
      branch: "شعبه",
      part: "بخش",
      date: "تاریخ",
      fromDate: "از تاریخ",
      toDate: "تا تاریخ",
      fromTime: "از ساعت",
      toTime: "تا ساعت",
      fromNumberMembers: "از تعداد اعضا",
      toNumberMembers: "تا تعداد اعضا",
      marriedOrSingle: "وضعیت تاهل",
      selectBank: "انتخاب بانک",
      desiredDay: "روز مورد نظر",
      specialCondition: "میخواهم یک شرط خاص در نظر بگیرم.",
      messageText: "متن پیام ",
    },
    caption: {
      yes: "بله",
      no: "خیر",
      ok: "تایید",
      dashboard: "داشبورد",
      incentiveDiscountPlans: "طرح‌های تخفیفی/تشویقی",
      profile: "ویرایش اطلاعات و رمز عبور حساب کاربری",
      password: "تغییر رمز عبور",
      avatar: "تصویر پروفایل کاربری",
      addBaseInformation: "ثبت اطلاعات پایه جدید",
      editBaseInformation: "ویرایش اطلاعات پایه",
      users: "مدیریت کاربران سامانه",
      addUser: "ثبت کاربر جدید",
      editUser: "ویرایش اطلاعات کاربر",
      products: "مدیریت محصولات و تولیدات",
      orders: "مدیریت و بررسی سفارشات",
      messages: "پیام‌ها و چت کاربران",
      tickets: "پیام‌ها و درخواست‌های مشتریان",
      loading: "در حال بارگذاری ...",
      singIn: "ورود به سامانه",
      singUp: "عضویت در سامانه",
      haveAccount: "حساب کاربری دارید؟",
      haveNotAccount: "حساب کاربری ندارید؟",
      forgotPassword: "رمز عبور را فراموش کرده‌اید؟",
      emptyItem: "موردی برای نمایش یافت نشد",
      emptyMessage: "لیست پیام‌های شما خالی می‌باشد",
      emptyTicket: "لیست تیکت‌های شما خالی می‌باشد",
      emptyNotify: "لیست اعلان‌ و هشدارها خالی می‌باشد",
      noMessage: "پیام جدیدی دریافت نکردید",
      noNotify: "اعلان جدیدی وجود ندارد",
      underConstruction:
        "این قسمت در حال توسعه می‌باشد و بزودی در دسترس قرار خواهد گرفت",
      rejectedTitle: "عدم دسترسی به سامانه",
      rejectedText: "حساب کاربری شما در این سامانه مسدود شده است",
      search: "جستجو",
      searchTable: "جستجو در جدول",
      searchAllColumns: "جستجو در همه ستون‌ها",
      delConfirm: "از حذف این مورد مطمئن هستید؟",
      resell: "آیا محصول برای فروش مجدد هست؟",
      userInfo: "اطلاعات کاربر",
      userLevel: "سطح کاربر",
      label: "برچسب",
      gift: "جایزه‌ها",
      discounts: "تخفیف‌ها",
      purchaseHistory: "تاریخچه خرید و کسب امتیاز",
      bankCards: "کارت‌‌های بانکی",
      addBankCards: "افزودن کارت",
      noCards: "موردی برای نمایش یافت نشد",
      addNewDesign: "طرح جدید اضافه کنید",
    },
    card: {
      score: "امتیاز",
      fromPrice: "از قیمت",
      toPrice: "تا قیمت",
      forEach: "به ازای هر",
      usedFor: "مورداستفاده برای",
      minPurchase: "حداقل خرید",
      maxPurchase: "حداکثر خرید",
      period: "بازه زمانی",
      times: "تعداد دفعات استفاده",
      purchaseFrq: "تعداد خرید برای اعمال هدیه",
      date: "تاریخ",
      fromDate: "از تاریخ",
      toDate: "تا تاریخ",
      fromTime: "از ساعت",
      toTime: "تا ساعت",
      level: "سطح مورد نظر",
      currentLevel: "سطح فعلی",
      upgrade: "ارتقایافته به",
      numberCustomers: "تعداد مشتریان",
      parts: "بخش‌ها",
    },
  };
}

export default label;

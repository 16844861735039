<template>
  <div class="header" v-if="profile">

    <v-navigation-drawer v-model="drawer" app right width="310" class="sidebar">
      <!-- <v-navigation-drawer v-model="drawer" app right width="280" class="sidebar"> -->
      <div class="sidebar__avatar-wrapper" dir="rtl">
        <div class="header-box">
          <div class="logo-box">
            <v-img src="@/assets/images/logo.png" contain />
          </div>
          <div class="title-brand">
            <h4 class="py-3">
              {{ profile.fullName }}
            </h4>
            <span>
              {{ profile.mobile }}
            </span>
            <span class="icon-phone mt-1"></span>
          </div>
        </div>
      </div>

      <v-list dir="rtl">
        <template v-for="(nav, navIndex) in navItems">
          <v-list-item v-if="nav.to" :class="!nav.active && 'link-disable'" v-show="checkAccess(nav)"
            :key="`${navIndex}-a`" :to="{ name: nav.to }" link>
            <v-list-item-icon>
              <div class="box-icon">
                <span :class="nav.icon"></span>
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center justify-space-between">
                <div>
                  {{ nav.label }}
                  <span style="font-size: 12px;color: red;">
                    {{ !nav.active ? "به زودی" : "" }}
                  </span>
                </div>
                <div>
                  <v-icon>mdi-chevron-left</v-icon>
                </div>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="nav.subItems" :key="`${navIndex}-b`" :prepend-icon="`${nav.icon} fa-em`"
            v-model="nav.active" append-icon="fas fa-chevron-down fa-sm">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ nav.label }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(sub, subIndex) in nav.subItems" :key="subIndex" :to="{ name: sub.to }" link>
              <v-list-item-icon>
                <v-icon small>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ sub.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>

      <!-- <span class="sidebar-bottom-caption">
        Persian Frontend Web Application v1.2.0
      </span> -->

    </v-navigation-drawer>
    <v-app-bar flat color="white" app>
      <v-img src="@/assets/images/drawer.svg" @click="toggleSidebar()" class="drawer-hide" width="25" height="63"
        contain v-if="drawer">
        <i class="fas fa-chevron-right"></i>
      </v-img>
      <v-btn @click="toggleSidebar()" class="drawer-show" style="right: 80px;" elevation="0" fab v-else><i
          class="fas fa-bars"></i>
      </v-btn>

      <router-link to="/">
        <v-toolbar-title>
          <v-img :src="require('@/assets/images/logo.png')" style="width: 50px; height: 60px;margin-top: 0px;"
            contain />
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>

      <Branch />

      <!-- <NotifyDropDown />

      <MessageDropDown /> -->

      <AccountDropDown />

    </v-app-bar>
  </div>
</template>
<style>
.link-disable {
  pointer-events: none !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";
import navItems from "@/containers/navbar.js";
import AccountDropDown from "./dropDown/Account";
// import MessageDropDown from "./dropDown/Message";
// import NotifyDropDown from "./dropDown/Notify";
import Branch from "./Branch";

export default {
  name: "TheHeader",
  components: {
    AccountDropDown,
    // MessageDropDown,
    // NotifyDropDown,
    Branch,
  },
  data() {
    return {
      alert,
      label,
      drawer: null,
      navItems,
    }
  },
  computed: {
    ...mapGetters({
      profile: "userProfile",
    })
  },
  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer
    },
    checkAccess(nav) {
      if (nav.ownerAccess && this.profile.roleId == 2) {
        return false;
      } else {
        return true;
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  beforeCreate() {
    // this.$store.dispatch('getCurrentUser');
  },
};
</script>

<template>
  <div class="header-branch">
    <!-- <a class="branch-box" @click="changeBranch">
      <a class="icon-Edit"></a>
      <h6 class="p-caption dark--text px-1">
        {{ branchItem }}
      </h6>
      <div class="vl"></div>
      <h6 class="p-caption dark--text px-2">
        {{ partItem }}
      </h6>
    </a> -->

    <!-- select Branch Modal -->
    <v-dialog v-model="branchModal" max-width="470px" overlay-color="third">
      <v-card>
        <v-card-title class="modal-header">
          <a class="close-modal" @click="close">
            <v-icon class="mr-1">
              mdi-close
            </v-icon>
          </a>
          <h3 class="mx-auto mt-2">
            {{ label.page.selectBranch }}
          </h3>
        </v-card-title>
        <v-card-text class="px-4">
          <v-container class="vino-form pt-4 mt-0">
            <v-row v-if="profile.roleId == 2">
              <v-col cols="12">
                <v-autocomplete v-model="selectedManager" :items="managerPartOptions" no-data-text="لیست خالی می‌باشد"
                  outlined rounded dense hide-details return-object class="mt-2" color="second">
                  <template #label>
                    <label class="p-label">
                      شعبه و بخش مدنظر را انتخاب کنید
                    </label>
                  </template>
                  <template v-slot:append>
                    <v-icon color="second"> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-autocomplete v-model="selectedBranch" :items="branchOptions" no-data-text="لیست خالی می‌باشد"
                  outlined rounded dense hide-details class="mt-2" color="second" @input="setParts">
                  <template #label>
                    <label class="p-label">
                      {{ label.form.selectBranch }}
                    </label>
                  </template>
                  <template v-slot:append>
                    <v-icon color="second"> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete v-model="selectedPart" :items="partOptions" no-data-text="ابتدا شعبه را انتخاب کنید"
                  outlined rounded dense hide-details class="mt-2" color="second" bg-color="error">
                  <template #label>
                    <label class="p-label">
                      {{ label.form.selectPart }}
                    </label>
                  </template>
                  <template v-slot:append>
                    <v-icon color="second"> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="11" class="mx-auto">
              <v-btn color="second" width="100%" height="45px" dark class="px-4 ml-2" rounded small depressed
                @click="setManagerBranch" v-if="profile.roleId == 2">
                {{ label.button.ok }}
              </v-btn>
              <v-btn color="second" width="100%" height="45px" dark class="px-4 ml-2" rounded small depressed
                @click="setBranch" v-else>
                {{ label.button.ok }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import label from "@/lang/StaticLabels.js";
import alert from "@/lang/AlertMessages.js";

export default {
  name: "SelectBranch",
  data() {
    return {
      label,
      alert,
      branchModal: false,
      selectedBranch: null,
      selectedPart: null,
      branchItem: '',
      partItem: '',
      selectedManager: null,
      managerPartOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      branchOptions: "operationX/vendoreBranchOptions",
      partOptions: "operationX/partOptions",
      loadingBtn: "loadingBtn",
      postCheck: "postCheck",
      profile: "userProfile",
    })
  },
  watch: {
    postCheck() {
      if (this.postCheck) {
        this.close();
        this.reloadData();
      }
    },
    branchOptions() {
      let branch = localStorage.getItem('activeBranch');
      let part = localStorage.getItem('activeBranchPart');
      if (branch && part) {
        let checkAccess = this.branchOptions.find(item => item.value == branch);
        if (checkAccess) {
          this.selectedBranch = Number(branch);
          this.selectedPart = Number(part);
          // this.$store.dispatch("operationX/getParts", this.selectedBranch);
        } else {
          localStorage.removeItem("activeBranch");
          localStorage.removeItem("activeBranchPart");
          this.selectedBranch = this.branchOptions[0].value;
          // this.$store.dispatch("operationX/getParts", this.selectedBranch);
        }
      } else {
        this.selectedBranch = this.branchOptions[0].value;
        // this.$store.dispatch("operationX/getParts", this.selectedBranch);
      }
    },
    partOptions() {
      if (this.partOptions && this.partOptions.length > 0) {
        if (this.selectedPart == null) {
          this.selectedPart = this.partOptions[0].value;
        }
        this.setItems();
      }
    },
  },
  methods: {
    setItems() {
      if (this.profile && this.profile.roleId == 2) {
        if (this.selectedManager) {
          localStorage.setItem('activeBranch', this.selectedManager.bnrachId);
          localStorage.setItem('activeBranchPart', this.selectedManager.value);
          this.branchItem = this.selectedManager.bnrachName;
          this.partItem = this.selectedManager.partName;
        }
        else {
          let checkAccess = this.profile.branchParts.find(item => item.id == this.selectedPart);
          if (checkAccess) {
            this.branchItem = checkAccess.branch;
            this.partItem = checkAccess.title;
          }
          else {
            this.branchItem = this.profile.branchParts[0].branch;
            this.partItem = this.profile.branchParts[0].title;
            localStorage.setItem('activeBranch', this.profile.branchParts[0].branchId);
            localStorage.setItem('activeBranchPart', this.profile.branchParts[0].id);
          }
        }
      }
      else {
        localStorage.setItem('activeBranch', this.selectedBranch);
        localStorage.setItem('activeBranchPart', this.selectedPart);
        let branch = this.branchOptions.find(item => item.value == this.selectedBranch);
        this.branchItem = branch.text;
        if (this.partOptions && this.partOptions.length > 0) {
          let part = this.partOptions.find(item => item.value == this.selectedPart);
          if (part) {
            this.partItem = part.text;
          }
        }
      }
    },
    setParts() {
      if (this.selectedBranch) {
        // this.$store.dispatch("operationX/getParts", this.selectedBranch);
        this.selectedPart = null;
      }
    },
    changeBranch() {
      if (this.profile && this.profile.roleId == 2) {
        this.profile.branchParts.forEach((item) => {
          const pushItem = {
            value: item.id,
            text: item.branch + ' | ' + item.title,
            bnrachId: item.branchId,
            bnrachName: item.branch,
            partName: item.title,
          };
          this.managerPartOptions.push(pushItem);
        });
      }
      this.branchModal = true;
    },
    setBranch() {
      if (this.selectedBranch && this.selectedPart) {
        this.setItems();
        this.branchModal = false;
        let href = window.location.href;
        window.location.href = href;
      } else {
        this.$store.commit("setError", alert.error.emptyFields);
      }
    },
    setManagerBranch() {
      if (this.selectedManager) {
        this.setItems();
        this.branchModal = false;
        let href = window.location.href;
        window.location.href = href;
      } else {
        this.$store.commit("setError", alert.error.emptyFields);
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    close() {
      this.branchModal = false;
    },
    reloadData() {
      // this.$store.dispatch("operationX/getVendoreBranchs");
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>

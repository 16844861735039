import Cookies from 'js-cookie';

function setCookie(name, token, expiresIn) {
    Cookies.set(name, token, { expires: expiresIn, path: '/' });
}

function getCookie(name) {
    return Cookies.get(name);
}

function deleteCookie(name) {
    Cookies.remove(name, { path: '/' });
}

function convertUtcToLocal(utcDateTime) {

    // Create a Date object from the UTC date-time string
    const utcDate = new Date(utcDateTime);

    // Convert UTC to Local Date Time
    const localTime = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

    // Get the current local date-time
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMilliseconds = localTime.getTime() - now.getTime();

    // Convert the difference to seconds (1 second = 1000 milliseconds)
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

    return [!!diffInSeconds, diffInSeconds];
}

function getPersianDateTime() {
    // Get the current datetime in ISO 8601 format
    const now = new Date().toISOString();

    // Convert to Persian date using the Intl.DateTimeFormat
    const persianDate = new Intl.DateTimeFormat('fa-IR-u-ca-persian', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(new Date(now));

    // Extract the time part
    const time = now.split('T')[1].split('.')[0];

    return [
        persianDate,
        time,
    ];
}




export { setCookie, getCookie, deleteCookie, convertUtcToLocal, getPersianDateTime };
export default {

  setVendorDetails(state, data) {
    state.vendorDetails = data;
  },

  setVendoreBranchs(state, data) {
    state.vendoreBranchs = data;
  },

  setVendoreBranch(state, data) {
    state.vendoreBranch = data;
  },

  setVendoreLine(state, data) {
    state.vendoreLine = data;
  },

  setParts(state, data) {
    state.parts = data;
  },

  setVendoreBranchCustomers(state, data) {
    state.vendoreBranchCustomers = data;
  },

  setCreditAndCashback(state, data) {
    state.creditAndCashback = data;
  },

  setVendorsLevel(state, data) {
    state.vendorsLevel = data;
  },

  setVendorsLabel(state, data) {
    state.vendorsLabel = data;
  },

  setCustomers(state, data) {
    state.customers = data;
  },
  setCustomer(state, data) {
    state.customer = data;
  },
  setCustomerLevel(state, data) {
    state.customerLevel = data;
  },
  setCustomerLabel(state, data) {
    state.customerLabel = data;
  },
  setCustomerHistoryTransactions(state, data) {
    state.customerHistoryTransactions = data
  },
  setCards(state, data) {
    state.cards = data;
  },
  setCustomerCheck(state, data) {
    state.customerCheck = data;
  },

  setSmartData(state, data) {
    state.smartData = data;
  },

  setSmartDataMessage(state, data) {
    state.smartDataMessage = data;
  },

  setSmartDataId(state, data) {
    state.smartDataId = data;
  },

  setPopulationCenters(state, data) {
    state.populationCenters = data;
  },
  setPopulationId(state, data) {
    state.populationId = data;
  },

  setRequests(state, data) {
    state.requests = data;
  },

  setPrizeShelf(state, data) {
    state.prizeShelf = data;
  },

  setVendorScore(state, data) {
    state.vendorScore = data;
  },
  setVendorScoreDetails(state, data) {
    state.vendorScoreDetails = data;
  },

  setSentBusinessRequest(state, data) {
    state.sentBusinessRequest = data;
  },

  setSentBranchRequest(state, data) {
    state.sentBranchRequest = data;
  },

  setReceivedBusinessRequest(state, data) {
    state.receivedBusinessRequest = data;
  },

  setReceivedBranchRequest(state, data) {
    state.receivedBranchRequest = data;
  },

  setReceivedReqDetail(state, data) {
    state.receivedReqDetail = data;
  },

  setVendorsList(state, data) {
    state.vendorList = data;
  },

  setPolls(state, data) {
    state.polls = data;
  },
  setPollDetails(state, data) {
    state.poll = data;
  },

  setTransactions(state, data) {
    state.transactions = data;
  },
  setTransaction(state, data) {
    state.transaction = data;
  },

  setDashboard(state, data) {
    state.dashboard = data;
  },

  setTransactionModal(state, data) {
    state.transactionModal = data;
  }

};

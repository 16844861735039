export default {

    vendorDetails: {},
    vendoreBranchs: [],
    vendoreBranch: {},
    vendoreLine: {},

    parts: [],
    
    vendoreBranchCustomers: [],

    vendorsLevel: [],
    vendorsLabel: [],

    customers: [],
    customer: null,
    customerLevel: {},
    customerLabel: {},
    cards: [],
    customerHistoryTransactions: [],
    customerCheck: null,

    smartData: [],
    smartDataId: {},
    smartDataMessage: [],
    creditAndCashback: [],

    populationCenters: [],
    populationId: {},

    requests: [],

    prizeShelf: [],

    vendorScore: [],
    vendorScoreDetails: {},

    sentBusinessRequest: [],
    sentBranchRequest: [],

    receivedBusinessRequest: [],
    receivedBranchRequest: [],
    receivedReqDetail: {},
    vendorList: [],

    polls: [],
    poll: {},

    transactions: [],
    transaction: {},

    dashboard: {},
    transactionModal: false
};

export default {
  checkCode: (state) => {
    return state.checkCode;
  },
  timer: (state) => {
    return state.timer;
  },
  postCheck: (state) => {
    return state.postCheck;
  },

  loginCheck: (state) => {
    return state.loginCheck;
  },

  forgotCheck: (state) => {
    return state.forgotCheck;
  },
  forgotBtn: (state) => {
    return state.forgotBtn;
  },

  userProfile: state => {
    return state.userProfile;
  },

  loadingBtn: state => {
    return state.loadingBtn;
  },

};

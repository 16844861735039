// Global Guards
/* eslint-disable */
import { getCookie } from "@/helper";
import router from "@/router";

const beforeEach = (to, from, next) => {
  var authUser = getCookie("authorization");
  console.log(authUser)
  console.log(to)
  if (to.meta.requiresAuth) {
    if (authUser) {
      console.log(!!authUser);
      next();
    } else {
      next({ name: "Login" });
    }
  }
  console.log("Down")
  next();
};

const login = (to, from, next) => {
  // if (localStorage.getItem('token')) {
  if (getCookie("authorization")) {
    console.log("Hello")
    next({ name: 'Dashboard' })
    // router.push('/').catch(() => { });
    // router.push({ name: "Dashboard" });
  }
  next()
};

const afterEach = (to, from) => {
  // Put your guard actions here
};

export default {
  beforeEach,
  login,
  afterEach,
};

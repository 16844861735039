export default {

  vendorDetails: (state) => {
    return state.vendorDetails;
  },
  vendoreBranchs: (state) => {
    return state.vendoreBranchs;
  },
  vendoreBranchOptions: (state) => {
    const vendoreBranchOptions = [];
    state.vendoreBranchs.forEach(func);
    function func(item) {
      console.log(item)
      const pushItem = {
        value: item.id,
        text: item.title,
        parts: item.lines,
      };
      vendoreBranchOptions.push(pushItem);
    }
    return vendoreBranchOptions;
  },

  vendoreBranch: (state) => {
    return state.vendoreBranch;
  },
  vendoreLine: (state) => {
    return state.vendoreLine;
  },

  vendoreBranchCustomers: (state) => {
    return state.vendoreBranchCustomers;
  },

  parts: (state) => {
    return state.parts;
  },
  partOptions: (state) => {
    const partOptions = [];
    state.parts.forEach(func);
    function func(item) {
      console.log(item)
      const pushItem = {
        value: item.id,
        text: item.title,
        line: item
      };
      console.log(pushItem)
      partOptions.push(pushItem);
    }
    return partOptions;
  },

  smartDataOptions: (state) => {
    const smartDataOptions = [];
    state.creditAndCashback.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      console.log(pushItem)
      smartDataOptions.push(pushItem);
    }
    return smartDataOptions;
  },
  vendorsLevel: (state) => {
    return state.vendorsLevel;
  },
  vendorLevelOptions: (state) => {
    const vendorLevelOptions = [];
    state.vendorsLevel.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.level.title,
        icon: item.level.iconPath,
      };
      vendorLevelOptions.push(pushItem);
    }
    return vendorLevelOptions;
  },

  vendorsLabel: (state) => {
    return state.vendorsLabel;
  },
  vendorsLabelOptions: (state) => {
    const vendorsLabelOptions = [];
    state.vendorsLabel.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.label.title,
        icon: item.label.iconPath,
      };
      vendorsLabelOptions.push(pushItem);
    }
    return vendorsLabelOptions;
  },


  customers: (state) => {
    return state.customers;
  },
  customer: (state) => {
    return state.customer;
  },
  customerLevel: (state) => {
    return state.customerLevel;
  },
  customerLabel: (state) => {
    return state.customerLabel;
  },
  customerHistoryTransactions: (state) => {
    return state.customerHistoryTransactions;
  },
  cards: (state) => {
    return state.cards;
  },
  customerCheck: (state) => {
    return state.customerCheck;
  },

  smartData: (state) => {
    return state.smartData;
  },

  smartDataId: (state) => {
    return state.smartDataId;
  },

  smartDataMessage: (state) => {
    return state.smartDataMessage;
  },

  populationCenters: (state) => {
    return state.populationCenters;
  },
  populationId: (state) => {
    return state.populationId;
  },

  requests: (state) => {
    return state.requests;
  },

  prizeShelf: (state) => {
    return state.prizeShelf;
  },
  prizeShelfOptions: (state) => {
    const prizeShelfOptions = [];
    state.prizeShelf.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
      };
      prizeShelfOptions.push(pushItem);
    }
    return prizeShelfOptions;
  },

  vendorScore: (state) => {
    return state.vendorScore;
  },
  vendorScoreDetails: (state) => {
    return state.vendorScoreDetails;
  },

  sentBusinessRequest: (state) => {
    return state.sentBusinessRequest;
  },

  sentBranchRequest: (state) => {
    return state.sentBranchRequest;
  },

  receivedBusinessRequest: (state) => {
    return state.receivedBusinessRequest;
  },

  receivedBranchRequest: (state) => {
    return state.receivedBranchRequest;
  },

  receivedReqDetail: (state) => {
    return state.receivedReqDetail;
  },

  vendorList: (state) => {
    return state.vendorList;
  },

  polls: (state) => {
    return state.polls;
  },
  poll: (state) => {
    return state.poll;
  },

  pollSendForOptions() {
    return [
      { value: 1, text: "ارسال برای سطح خاص" },
      { value: 2, text: "ارسال بعد از هر خرید" },
      { value: 3, text: "ارسال برای همه" },
    ];
  },

  pollQuestionTypeOptions() {
    return [
      { value: 1, text: "چندگزینه‌ای، تک انتخابی" },
      { value: 2, text: "چندگزینه‌ای، چند انتخابی" },
    ];
  },

  transactions: (state) => {
    return state.transactions;
  },
  transaction: (state) => {
    return state.transaction;
  },
  dashboardTransactions: (state) => {
    const result = state.transactions.slice(0, 5);
    return result;
  },

  dashboard: (state) => {
    return state.dashboard;
  },
  transactionModal: state => {
    return state.transactionModal;
  }
};

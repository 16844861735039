export default {

  allDiscounts: (state) => {
    return state.allDiscounts;
  },

  discountCounter: (state) => {
    return state.discountCounter;
  },

  point: (state) => {
    return state.point;
  },

  forget: (state) => {
    return state.forget;
  },

  offPlan: (state) => {
    return state.offPlan;
  },

  sale: (state) => {
    return state.sale;
  },

  event: (state) => {
    return state.event;
  },

  cashback: (state) => {
    return state.cashback;
  },

  credit: (state) => {
    return state.credit;
  },

  loyalty: (state) => {
    return state.loyalty;
  },

  public: (state) => {
    return state.public;
  },


  // Static Getters ---------------------------------------------------------------

  scoreTypeOptions() {
    return [
      { value: 1, text: "امتیازدهی ثابت" },
      { value: 2, text: "امتیازدهی متغیر" },
    ];
  },

  dateTypeOptions() {
    return [
      { value: 1, text: "روز هفته" },
      { value: 2, text: "ماه" },
    ];
  },

  dateValueOptions() {
    return [
      { value: 1, text: "یکشنبه" },
      { value: 2, text: "دوشنبه" },
      { value: 3, text: "سه‌شنبه" },
      { value: 4, text: "چهارشنبه" },
      { value: 5, text: "پنج‌شنبه" },
      { value: 6, text: "جمعه" },
      { value: 7, text: "شنبه" },
    ];
  },

  monthOptions() {
    return [
      { value: 1, text: "فروردین" },
      { value: 2, text: "اردیبهشت" },
      { value: 3, text: "خرداد" },
      { value: 4, text: "تیر" },
      { value: 5, text: "مرداد" },
      { value: 6, text: "شهریور" },
      { value: 7, text: "مهر" },
      { value: 8, text: "آبان" },
      { value: 9, text: "آذر" },
      { value: 10, text: "دی" },
      { value: 11, text: "بهمن" },
      { value: 12, text: "اسفند" },
    ];
  },

  timingOptions() {
    return [
      { value: 1, text: "بازه زمانی ثابت" },
      { value: 2, text: "بازه زمانی متغیر" },
    ];
  },

  durationTypeOptions() {
    return [
      { value: 1, text: "روزانه" },
      { value: 2, text: "هفتگی" },
      { value: 3, text: "ماهانه" },
    ];
  },

  offTypeOptions() {
    return [
      { value: 1, text: "پلکانی" },
      { value: 2, text: "نقدی" },
      { value: 3, text: "اعتباری" },
    ];
  },

  eventTypeOptions() {
    return [
      { value: 1, text: "عمومی" },
      { value: 2, text: "اختصاصی (تاریخ تولد مشتری)" },
    ];
  },



};

let language = localStorage.getItem("selectedLanguage");
let alert;
if (language && language === "En") {
  alert = {
    success: {
      save: "Success, Your data was saved",
      submit: "The form has been submitted successfully",
      edit: "Edited successfully",
      addUser: "User Account Created successfully",
      editUser: "the User's information Edited successfully",
      delete: "The item has been deleted successfully",
      deleteUser: "The User has been deleted successfully",
      login: "Welcome to VINO Application ...",
      register: "Congratulations, your account has been created",
      changeStatus: "The status successfully changed",
      changeAvatar: "Your profile image was updated",
      editProfile: "Your profile has been updated",
      changePassword: "Success, Your password has been changed",
      resetPassword: "Success, the password has been Reset",
      forgotPassword: "Your Password has been Reset and Sent to Your Email Address",
      upload: "Your File has been Uploaded",
      generateCode: "the OTP Code has been Generated",
      closeMessage: "your Message has been Closed",
      addOrder: "your Order have been submitted",
    },
    error: {
      access: "Sorry, you don't have access",
      notFound: "The data was not found",
      server: "Could not connect to server",
      emptyFields: "please fill the required items",
      rePassword: "the password repeat does not match with password",
      delete: "you can not delete this item",
    },
  };
}
else {
  alert = {
    success: {
      save: "اطلاعات شما با موفقیت ذخیره شد",
      submit: "اطلاعات مدنظر با موفقیت ثبت شد",
      edit: "اطلاعات مدنظر باموفقیت ویرایش شد",
      addUser: "کاربر جدید با موفقیت ثبت شد",
      editUser: "اطلاعات کاربر با موفقیت ویرایش شد",
      delete: "با موفقیت حذف شد",
      deleteUser: "کاربر مدنظر با موفقیت حذف شد",
      login: "به سامانه سنجاب خوش آمدید ...",
      register: "حساب کاربری شما با موفقیت ایجاد شد",
      changeStatus: "وضعیت مدنظر با موفقیت ویرایش شد",
      changeAvatar: "تصویر پروفایل بروزرسانی شد",
      editProfile: "اطلاعات پروفایل شما با موفقیت ویرایش شد",
      changePassword: "رمز عبور شما با موفقیت ویرایش شد",
      resetPassword: "رمز عبور با موفقیت بازنشانی شد",
      forgotPassword: "رمز عبور جدید ثبت و به پست الکترونیک شما ارسال شد",
      upload: "با موفقیت بارگذاری شد",
      generateCode: "کد یکبار مصرف ایجاد شد",
      sentCode: "کد احراز هویت به شماره شما ارسال شد",
      closeMessage: "پیام با موفقیت بسته شد",
      addOrder: "سفارش شما با موفقیت ثبت شد",
      addCustomers: "اطلاعات مشتریان شما با موفقیت ثبت شد",
      addTransactions: "تراکنش‌های شما با موفقیت ثبت شد",
    },
    error: {
      access: "متاسفانه شما به این قسمت دسترسی ندارید",
      notFound: "اطلاعات مدنظر یافت نشد",
      server: "مشکلی در ارتباط با سرور وجود دارد",
      emptyFields: "لطفا موارد الزامی را وارد کنید",
      emptyPollQuestion: "نظرسنجی باید حداقل شامل یک سوال باشد",
      emptyPollOption: "هر سوال باید حداقل دو گزینه داشته باشد",
      rePassword: "تکرار رمز عبور همخوانی ندارد",
      delete: "امکان حذف این مورد وجود ندارد",
      mobileField: "لطفا شماره همراه راه به صورت صحیح و همراه با صفر اول وارد کنید",
      searchKey: "برای جستجو باید حداقل دو حرف وارد کنید",
      updateBusiness: "شما اشتراک گذاری فعال دارید!"
    },
  };
}

export default alert;

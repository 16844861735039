import label from "@/lang/StaticLabels.js";

let navItems = [
  {
    to: "Dashboard",
    label: label.menu.dashboard,
    icon: "icon-dashboard",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  {
    to: "StoreManager",
    label: label.menu.manageShop,
    icon: "icon-Shop-1",
    active: true,
    subItems: false,
    ownerAccess: true,
  },
  // {
  //   to: "customerCategories",
  //   label: label.menu.customerCategories,
  //   icon: "icon-category",
  //   active: true,
  //   subItems: false,
  //   ownerAccess: true,
  // },
  {
    to: "CustomerList",
    label: label.menu.manageCustomer,
    icon: "icon-number-customer",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  {
    to: "Transactions",
    label: 'تراکنش و پرداخت‌ها',
    icon: "icon-Money-1",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  {
    to: "DiscountList",
    label: label.menu.incentiveDiscountPlans,
    icon: "icon-Discount-1",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  // {
  //   to: "PrizeShelf",
  //   label: label.menu.prizeShelf,
  //   icon: "icon-Gift-1",
  //   active: true,
  //   subItems: false,
  //   ownerAccess: false,
  // },
  // {
  //   to: "ManageGift",
  //   label: label.menu.manageGift,
  //   icon: "icon-Gift-1",
  //   active: true,
  //   subItems: false,
  //   ownerAccess: false,
  // },
  {
    to: "VoteList",
    label: label.menu.vote,
    icon: "icon-Review-1",
    active: false,
    subItems: false,
    ownerAccess: false,
  },
  {
    to: "SmartDataList",
    label: label.menu.smartData,
    icon: "icon-Smart-1",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  {
    to: "CustomerClub",
    label: label.menu.shareCustomerClub,
    icon: "icon-Share-1",
    active: true,
    subItems: false,
    ownerAccess: false,
  },
  // {
  //   to: "populationCentersList",
  //   label: label.menu.populationCenters,
  //   icon: "icon-Users-1",
  //   active: true,
  //   subItems: false,
  //   ownerAccess: false,
  // },
  // {
  //   to: "RequestList",
  //   label: label.menu.youerRequestList,
  //   icon: "icon-desc-1",
  //   active: true,
  //   subItems: false,
  //   ownerAccess: false,
  // },
];

export default navItems;

<template>
  <v-app>

    <the-alerts />
    <the-header />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </v-main>

    <the-loading />

  </v-app>
</template>

<script>
import TheHeader from "@/containers/TheHeader";
import TheAlerts from "@/components/TheAlerts";
import TheLoading from "@/components/TheLoading";

export default {
  name: "TheContainer",
  components: {
    TheHeader,
    TheAlerts,
    TheLoading,
  },
};
</script>

export default {

  allDiscounts: [],

  discountCounter: {},

  point: [],

  forget: [],

  offPlan: [],

  sale: [],

  event: [],

  cashback: [],

  credit: [],

  loyalty: [],

  public: [],

};

export default {

  users: (state) => {
    return state.users;
  },
  user: (state) => {
    return state.user;
  },
  userOptions: (state) => {
    const userOptions = [];
    state.users.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.name,
      };
      userOptions.push(pushItem);
    }
    return userOptions;
  },

  levels: (state) => {
    return state.levels;
  },
  levelOptions: (state) => {
    const levelOptions = [];
    state.levels.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
        icon: item.iconPath,
      };
      levelOptions.push(pushItem);
    }
    return levelOptions;
  },

  labels: (state) => {
    return state.labels;
  },
  labelOptions: (state) => {
    const labelOptions = [];
    state.labels.forEach(func);
    function func(item) {
      const pushItem = {
        value: item.id,
        text: item.title,
        icon: item.iconPath,
      };
      labelOptions.push(pushItem);
    }
    return labelOptions;
  },


  // Static Getters ---------------------------------------------------------------

  isMarreidOptions() {
    return [
      { value: false, text: "مجرد" },
      { value: true, text: "متاهل" },
    ];
  },
  genderOptions() {
    return [
      { value: false, text: "مرد" },
      { value: true, text: "زن" },
    ]
  },
};
